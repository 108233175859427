import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import FunFactsArea from "../components/Common/FunFactsArea";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';

import AboutImg from "../assets/images/services-left-image/spaceship.png";

const About2 = () => (
    <Layout>
        <SEO title="About Us Page 2" /> 

        <Navbar />

        <PageBanner pageTitle="About Us" />

        <div className="about-area ptb-80">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="ml-about-img">
                            <img src={AboutImg} alt="image" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="ml-about-content">
                            <span className="sub-title">About Us</span>
                            <h2>Engaging New Audiences Through Smart Approach</h2>
                            <div className="bar"></div>

                            <p>We are a Seattle-based startup focused on transforming the child learning and entertainment industry.</p>

                            <p>Developed by experts, our research-based, approach goes beyond a child's imagination to build confidence, promote problem-solving skills, and foster a lifelong love of reading and learning.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="about-inner-area">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our History</h3>
                                <p>Our founder first concepted StoryToons back in 2013. Working on different ideas since then, the dream to deliver is finally happening.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our Mission</h3>
                                <p>StoryToon’s mission is to accelerate children development and early education through technology and innovation.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Who We Are</h3>
                                <p>We're a group of very passionate individuals who want to bring the best level of education and entertainment to our customer base.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <FunFactsArea />
        
        <Footer />
    </Layout>
)

export default About2;

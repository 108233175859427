import React from 'react';
import { Link } from "gatsby";

import MapImg from "../../assets/images/map.png";

const FunFactsArea = () => {
    return (
        <div className="funfacts-area ptb-80">
            <div className="container">
                <div className="section-title">
                    <h2>We always try to understand users expectation</h2>
                    <div className="bar"></div>
                    <p>StoryToons is creating the next disruption in the online children learning and entertainment industry.</p>
                </div>

                <div className="contact-cta-box">
                    <h3>Have Any Questions About Us?</h3>
                    <p>Don't hesitate to contact us</p>

                    <Link to="/contact" className="btn btn-primary">
                        Contact Us
                    </Link>
                </div>

                <div className="map-bg">
                    <img src={MapImg} alt="map" />
                </div>
            </div>
        </div>
    )
}

export default FunFactsArea;